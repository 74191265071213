import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { ContactService } from '../contact.service';
import { Contact } from '../contact';
declare var $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  error:any;
  captchaText: string;
  userCaptchaInput: string;
  captchaImageUrl: string;
  
  reasons = [{ reason: "Business Strategy" }, { reason: "Fashion, Apparel, & Textile Law" }, { reason: "Music" }, { reason: 'Liability & Risk' }, { reason: "TV, Film, & Theatre" }, { reason: "Digital Media & Tech" }, { reason: 'Other' }];

  constructor(private formBuilder: FormBuilder,private _contactService: ContactService) { }

  ngOnInit() {
    this.generateCaptcha();
      this.contactForm = this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        pone: ['', Validators.required],
        ptwo:['', Validators.required],
        pthree:['',Validators.required],
        message:['', Validators.required],
        userCaptchaInput:['', Validators.required],
        bs:[''],
        fatl:[''],
        mus:[''],
        lr:[''],
        tft:[''],
        dmt:[''],
        other:['']
    });

    $(document).ready(function () {
      $('#p1').mask('000');
    });
    $(document).ready(function () {
      $('#p2').mask('000');
    });
    $(document).ready(function () {
      $('#p3').mask('000');
    });
    
  }

  generateCaptcha() {
    this.captchaText = Math.random().toString(36).substring(2, 8); // Random string (alphanumeric)
    this.createCaptchaImage(this.captchaText);
  }

  // Function to create CAPTCHA image from the generated text
  createCaptchaImage(captchaText: string) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 150;
    canvas.height = 50;
    // Set the background color
  ctx.fillStyle = '#ccc'; // Light gray background color
  ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the entire canvas with the background color

  // Set the text properties
    ctx.font = '30px Arial';
    ctx.fillStyle = '#000';
    ctx.fillText(captchaText, 10, 35); // Draw the text on the canvas

    this.captchaImageUrl = canvas.toDataURL(); // Get the image URL from canvas
  }

  get f() { return this.contactForm.controls; }

    onSubmit() {
      if (this.userCaptchaInput === this.captchaText) {
        this.submitted = true;

        // stop here if form is invalid
        if (this.contactForm.invalid) {
            return;
        }

        // display form values on success
        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactForm.value, null, 4));
       // console.log(this.contactForm.value);
         //console.log(this.addForm.value);
        this._contactService.sendMail(this.contactForm.value).subscribe(data => {
          this.error="Message Sent Successfully"
          this.submitted = false;
          this.contactForm.reset();
      });
      } else {
        alert('Captcha is incorrect. Please try again.');
        this.generateCaptcha(); // Reload CAPTCHA if incorrect
      }
        
    }
}
